
import React from 'react';

const FAQ = ({ question, answer }) => {
  return (
    <div tabIndex="0" className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
      <div className="collapse-title text-xl font-medium">
        {question}
      </div>
      <div className="collapse-content">
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FAQ;
