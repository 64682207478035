
import React from 'react';

const ContactForm = () => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Get in Touch</h2>
        <form>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input type="text" placeholder="email@example.com" className="input input-bordered" />
          </div>
          <div className="form-control mt-4">
            <label className="label">
              <span className="label-text">Message</span>
            </label>
            <textarea className="textarea textarea-bordered" placeholder="Your message"></textarea>
          </div>
          <div className="form-control mt-6">
            <button className="btn btn-primary">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
