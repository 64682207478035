import React from 'react';

const HeroSection = () => {
  return (
    <div className="min-h-screen text-center hero bg-base-200 lg:text-left">
      <div className="flex-col hero-content lg:flex-row-reverse lg:gap-10">
        <div className="shadow-2xl mockup-phone border-primary">
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <img src='./screenshot.jpeg' alt="Aioskeys App" className="h-auto max-w-full rounded-lg" />
            </div>
          </div>
        </div>
        <div>
          <h1 className="mb-4 text-6xl font-bold text-primary">Aioskeys</h1>
          <p className="py-6 text-xl">
            Intelligent predictions, swipe typing, and a personalized typing experience await you. Elevate your iOS typing with Aioskeys.
          </p>
          <button className="transition-shadow duration-300 shadow-xl btn btn-primary btn-lg hover:shadow-2xl">
            Start Typing Smarter Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
