import React from "react";

const Testimonial = ({ quote, author, position, img }) => {
  return (
    <div className="text-center shadow-xl card bg-base-100">
      <div className="">
        <div className="shadow-xl avatar">
          <div className="w-16 rounded-full ring ring-primary ring-offset-base-100">
            {/* Avatar Image Here */}
            {/* use unsplash random api */}
            <img
              src={img || "https://source.unsplash.com/100x100/?portrait"}
              alt="avatar"
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <p>"{quote}"</p>
        <div className="items-center mt-4 ">
          <div>
            <h5 className="font-bold">{author}</h5>
            <p>{position}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
