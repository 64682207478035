import './App.css';
import HeroSection from './components/Hero';
import FeatureCard from './components/FeatureCard';
import Testimonial from './components/Testimonial';
import FAQ from './components/FAQ';
import ContactForm from './components/ContactForm';
import { FaBottleDroplet } from "react-icons/fa6";
import NavBar from './components/NavBar';

function App() {
  const features = [
    {
      title: 'Predictive Typing',
      description: 'AI-powered predictions to speed up your typing.',
      icon: <FaBottleDroplet color='blue' size={20}/>, // Replace with the path to your icon
    },
    {
      title: 'Predictive Typing',
      description: 'AI-powered predictions to speed up your typing.',
      icon: <FaBottleDroplet color='green' size={20} />, // Replace with the path to your icon
    },
    {
      title: 'Predictive Typing',
      description: 'AI-powered predictions to speed up your typing.',
      icon: <FaBottleDroplet  color='red' size={20} /> // Replace with the path to your icon
    },
    // ... other features
  ];

  const testimonials = [
    {
      quote: 'This app has changed the way I type on my phone. Highly recommended!',
      author: 'Jane Doe',
      position: 'Professional Writer',
      // ... other testimonials
    },
  ];

  const faqs = [
    {
      question: 'How does the keyboard integrate with iOS?',
      answer: 'Aioskeys uses the standard keyboard extension API provided by iOS.',
      // ... other FAQs
    },
    {
      question: 'How does the keyboard integrate with iOS?',
      answer: 'Aioskeys uses the standard keyboard extension API provided by iOS.',
      // ... other FAQs
    },
  ];

  const navigation = [
    // { name: 'Item 1', href: '#'},
    // { name: 'Parent', href: "#" },
    // { name: 'Download', href: '#'},
    { name: 'Features', href: '#features' },
    { name: 'Testimonials', href: '#testimonials' },
    { name: 'FAQs', href: '#faqs' },
    { name: 'Contact', href: '#contact' },
  ];

  return (
    <div className="App">
      <NavBar items={navigation} />
      <HeroSection />
      <section className="features lg:grid lg:grid-cols-3" id="features">
        {features.map((feature, index) => (
          <FeatureCard
            key={feature.title}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}
      </section>
      <section className="grid grid-cols-1 testimonials " id="testimonials">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={testimonial.author}
            quote={testimonial.quote}
            author={testimonial.author}
            position={testimonial.position}
          />
        ))}
      </section>
      <section className="faqs" id="faqs">
        {faqs.map((faq, index) => (
          <FAQ
            key={faq.question}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </section>
      <div className=''/>
      <section className="contact" id="contact">
        <ContactForm />
      </section>
    </div>
  )
}

export default App;
