
import React from 'react';

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="shadow-xl card lg:card-side bg-base-100">
      <figure className="p-6">
        {/* <img src={icon} alt="Feature icon" className="rounded-xl" /> */}
        {icon}
      </figure>
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
